import Drapeau from "../components/Drapeau"
import Navbar from "../components/Navbar"

export default function Flags () {

    return (
        <div>
            <Navbar/>
            <Drapeau />
        </div>
    )
}