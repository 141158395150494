export default function Baselinehome () {

    return (
        <div className="blbody">
            <div className="flex1">
                <div>
                    <div className="baseline">it's easy</div>
                    <div className="baseline">to find someone to talk to !</div>
                </div>
                <div className="" >
                <a href="/list_flags">
                    <div className="go" ><a href="/list_flags">
                    <div className="go2" ></div>
                        Let's go ! </a></div>
                        <img className="bulle" src="img/bulle.png"/>
                    </a>
                </div>
            </div>
            <div className="contbl">
                <img className="baseline-img" src="img/44658.png"/>
                    <div>
                        <a href="/form_translator"><div className="register" ><a href="/form_translator">Become a translator click Here ! </a></div>
                        <img className="bulle2"  href="/form_translator" src="img/bulle.png"/></a>
                    </div>
                <div className="">
                    <div className="indic1" href="">More than 367 Free translators registered</div>
                    <div className="indic2" href="">96 different languages available </div>
                    <div className="indic3" href="">58 professionals offering their services</div>
                </div>
            </div>

        </div>
    )
}
